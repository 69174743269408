<template>
<section class="wrapper bg-light">
  <div class="container py-7 py-md-8">

    <ul class="nav nav-tabs nav-pills">
      <li class="nav-item">
        <a class="nav-link active my-1" data-bs-toggle="tab" href="#tab1">
          <span>Compte</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link my-1" data-bs-toggle="tab" href="#tab2">
          <span>Données personnelles</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link my-1" data-bs-toggle="tab" href="#tab3">
          <span>Contact</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link my-1" data-bs-toggle="tab" href="#tab4">
          <span>Profil investisseur</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link my-1" data-bs-toggle="tab" href="#tab5">
          <span>Justificatifs</span>
        </a>
      </li>


      <li class="nav-item">
        <a class="nav-link my-1" data-bs-toggle="tab" href="#tab6">
          <span>Convention</span>
        </a>
      </li>

    </ul>
    <hr class="double my-2" />
    <!-- /.nav-tabs -->

    <div class="tab-content">

      <!--/.tab-pane -->
      <div class="tab-pane fade  show active" id="tab1">
        <h4>Créer votre compte client</h4>
        <div class="form-floating mb-4">
          <input v-model="telephone" id="telephone" name="telephone" type="text" class="form-control" placeholder="Numéro de téléphone mobile">
          <label for="telephone ">Numéro de téléphone mobile</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="email" id="email" name="email" type="email" class="form-control" placeholder="Email">
          <label for="email">Email</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="password" id="password" name="password" type="password" class="form-control" placeholder="Mot de passe pour accéder au compte">
          <label for="email">Mot de passe pour accéder au compte</label>
        </div>

        <a class="btn btn-success" @click="form_connect">Valider</a>

        <p>
          <br /> Avec Berlcoin, nous construisons un écosystème qui est encadré par les régulateurs compétents des marchés financiers (AMF & Bafim).


          Nous prenons la réglementation au sérieux.
          Cela signifie que nous devons à tout moment savoir qui sont nos clients investisseurs et nous vous demandons donc, lors de votre inscription, de suivre notre processus AML et KYC.
          <br />
          <br />Au cours de ce processus, nous vous demandons d'où vient l'argent que vous envisagez d'investir (AML) et vous devez vous identifier.
          Selon le montant que vous souhaitez investir cette identification pourra aussi se faire par prise de photos ou en utilisant votre webcam (ou smartphone) pour une identification vidéo.
          Dans certains cas nous pouvons vous contacter et vous demander des informations supplémentaires sur l'origine des fonds.
          <br /> <br /> Avant de réaliser votre opération d'investissement vous devez respecter les obligations légales et constituer votre dossier client. Vous allez maintenant réaliser les étapes suivantes :
          <br />
          <br /> Saisir vos informations personnelles
          <br /> Renseigner votre profil investisseur.
          <br /> Télécharger vos documents justificatifs
          <br /> Accepter la convention investisseur
          <br />
          <br /> Une fois votre dossier validé, vous pourrez investir dans le Berlcoin.
           validera votre questionnaire investisseur au plus vite. Vous aurez accès à la documentation du projet d'investissement. Vous pourrez souscrire à l'achat de titres, signer le bulletin de souscription et payer. Néanmoins
          votre investissement ne sera définitivement validé qu'à la validation de votre pièce d'identité.
          <br />

        </p>
      </div>
      <div class="tab-pane fade  " id="tab2">


        <div class="form-select-wrapper">
          <select class="form-select" v-model="civilite" aria-label="Default select example">
            <option value="">Civilité</option>
            <option value="1">Monsieur</option>
            <option value="2">Madame</option>
            <option value="3">Non binaire</option>
          </select>
        </div>
        <br />
        <div class="form-floating mb-4">
          <input v-model="firstname" id="firstname" name="firstname" type="text" class="form-control  red" placeholder="Prénom">
          <label for="email">Prénom</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="name" id="name" name="name" type="text" class="form-control" placeholder="Prénom">
          <label for="name">Nom</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="datenaissance" id="datenaissance" name="datenaissance" type="date" class="form-control" placeholder="Date de naissance">
          <label for="datenaissance">Date de naissance</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="departementnaissance" id="departementnaissance" name="departementnaissance" type="number" class="form-control" placeholder="Département naissance (numéro)">
          <label for="departementnaissance">Département naissance (numéro)</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="villenaissance" id="villenaissance" name="villenaissance" type="text" class="form-control" placeholder="Ville de naissance">
          <label for="villenaissance">Ville de naissance</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="nationalite" id="nationalite" name="nationalite" type="text" class="form-control" placeholder="Nationalité">
          <label for="nationalite">Nationalité</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="residencefiscale" id="residencefiscale" name="residencefiscale" type="text" class="form-control" placeholder="Pays de résidence fiscale">
          <label for="residencefiscale">Pays de résidence fiscale</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="profession" id="profession" name="profession" type="text" class="form-control" placeholder="Profession Indiquer la dernière fonction occupée si retraité ou sans emploi">
          <label for="profession"> Profession Indiquer la dernière fonction occupée si retraité ou sans emploi</label>
        </div>

        <div class="form-select-wrapper">
          <select v-model="situationfamille" class="form-select" aria-label="Situation de famille" name="situationfamille" id="situationfamille">
            <option selected>Situation de famille</option>
            <option value="1">Célibataire</option>
            <option value="2">Marié(e)</option>
            <option value="3">Pacsé(e)</option>
            <option value="4">Veuf(e)</option>
            <option value="5">Divorcé(e)</option>
          </select>
        </div>
        <br />
        <a class="btn btn-success" @click="form_perso">Valider</a>

      </div>
      <!--/.tab-pane -->
      <div class="tab-pane fade" id="tab3">
        <div class="form-select-wrapper">
          <select v-model="profil" class="form-select" aria-label="Default select example">
            <option value="1">Particulier</option>
            <option value="2">Entreprise</option>
          </select>
        </div>
        <br />
        <div class="form-floating mb-4">
          <input v-model="siren" id="siren" name="numersirenorue" type="text" class="form-control" placeholder="siren">
          <label for="siren">Numero de siren si Entreprise</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="numerorue" id="numerorue" name="numerorue" type="text" class="form-control" placeholder="numerorue">
          <label for="numerorue">Numero de rue</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="voie" id="voie" name="voie" type="text" class="form-control" placeholder="Voie">
          <label for="voie">Voie</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="complement1" id="complement1" name="complement1" type="text" class="form-control" placeholder="Complément 1">
          <label for="Complément 1">Complément 1</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="complement2" id="complement2" name="complement2" type="text" class="form-control" placeholder="Complément 2">
          <label for="Complément 2">Complément 2</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="codepostal" id="codepostal" name="codepostal" type="text" class="form-control" placeholder="Code postal">
          <label for="codepostal">Code postal</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="ville" id="ville" name="ville" type="text" class="form-control" placeholder="Ville">
          <label for="ville ">Ville</label>
        </div>
        <div class="form-floating mb-4">
          <input v-model="pays" id="pays" name="pays" type="text" class="form-control" placeholder="Pays">
          <label for="pays ">Pays</label>
        </div>


        <div class="form-floating mb-4">
          <input v-model="telephone" name="telephone" type="text" class="form-control" placeholder="Numéro de téléphone mobile">
          <label for="telephone ">Numéro de téléphone mobile</label>
        </div>

        <div class="form-floating mb-4">
          <input v-model="email" name="email" type="email" class="form-control" placeholder="Email">
          <label for="email">Email</label>
        </div>

        <a class="btn btn-success" @click="form_contact">Valider</a>


      </div>
      <div class="tab-pane fade" id="tab4">
        <h4>VOS OBJECTIFS D'INVESTISSEMENT </h4>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="preparerretraire" v-model="preparerretraire">
          <label class="form-check-label" for="preparerretraire"> Préparer ma retraire </label>
        </div>


        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="diversifierinvestissements" v-model="diversifierinvestissements">
          <label class="form-check-label" for="diversifierinvestissements"> Diversifier mes investissements </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="valorisercapital" v-model="valorisercapital">
          <label class="form-check-label" for="valorisercapital"> Valoriser un capital </label>
        </div>


        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="constituerepargne" v-model="constituerepargne">
          <label class="form-check-label" for="constituerepargne"> Me constituer une épargne disponible à tout moment </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="avantagesfiscaux" v-model="avantagesfiscaux">
          <label class="form-check-label" for="avantagesfiscaux"> Disposer d'avantages fiscaux </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="revenusreguliers" v-model="revenusreguliers">
          <label class="form-check-label" for="revenusreguliers"> Me constituer des revenus réguliers </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="autres" v-model="autres">
          <label class="form-check-label" for="autres"> Autres </label>
        </div>


        Votre horizon de placement pour le capital que vous envisagez d'investir :

        <div class="form-select-wrapper">
          <select class="form-select" aria-label="horizon de placement" v-model="horizon" id="horizon">
            <option selected>horizon de placement</option>
            <option value="1">moins de 2 ans</option>
            <option value="2">2 à 5 ans</option>
            <option value="3">5 à 7 ans</option>
            <option value="3">Plus de 7 ans</option>

          </select>
        </div>


        <br />

        <h4>VOTRE SITUATION PERSONNELLE</h4>
        Votre situation professionnelle ?
        <div class="form-select-wrapper">
          <select class="form-select" aria-label="Votre situation professionnelle ?" v-model="situationpro" id="situationpro">
            <option value="Profession libérale">Profession libérale</option>
            <option value="Cadre">Cadre</option>
            <option value="Salarié">Salarié</option>
            <option value="Artisan / commerçant">Artisan / commerçant</option>
            <option value="Exploitant agricole">Exploitant agricole</option>
            <option value="Etudiant">Etudiant</option>
            <option value="Retraité">Retraité</option>
            <option value="Sans emploi">Sans emploi</option>
          </select>
        </div>

        <br />

        <div class="form-floating mb-4">
          <input id="enfant" v-model="enfant" type="number" class="form-control" placeholder="Nombre d'enfant à charge">
          <label for="enfant">Nombre d'enfant à charge</label>
        </div>


        <h4>VOS CONNAISSANCES ET EXPERIENCES EN MATIERE D'INVESTISSEMENT</h4>
        Avez-vous exercé des fonctions dans les domaines liés à la finance ou aux marchés financiers au cours des 3 dernières années ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="fonctionsfinance" v-model="fonctionsfinance">
          <label class="form-check-label" for="fonctionsfinance">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="fonctionsfinance" v-model="fonctionsfinance">
          <label class="form-check-label" for="fonctionsfinance">NON</label>
        </div>

        Une action donne-t-elle droit à son propriétaire au versement systématique d'un dividende chaque année ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="dividende" v-model="dividende">
          <label class="form-check-label" for="dividende">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="dividende" v-model="dividende">
          <label class="form-check-label" for="dividende">NON</label>
        </div>


        Toutes choses égales par ailleurs, vous attendez-vous à ce qu’une obligation à 3% soit plus risquée qu’une obligation à 7% ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="risqueobligation" v-model="risqueobligation">
          <label class="form-check-label" for="risqueobligation">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="risqueobligation" v-model="risqueobligation">
          <label class="form-check-label" for="risqueobligation">NON</label>
        </div>



        Êtes-vous un investisseur averti ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="averti" v-model="averti">
          <label class="form-check-label" for="averti">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="averti" v-model="averti">
          <label class="form-check-label" for="averti">NON</label>
        </div>


        Si vous êtes inscrit dans le fichier de l’AMF en tant qu’investisseur qualifié au sens de l’article L411-2 du code monétaire et financier, veuillez nous indiquer votre numéro d’inscription

        <div class="form-floating mb-4">
          <input id="fichieramf" v-model="fichieramf" type="text" class="form-control" placeholder="fichieramf ">
          <label for="fichieramf">Numéro d'inscription</label>
        </div>


        Avez-vous déjà investi sur les produits, service ou marchés ci-dessous?
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="actioncotees" v-model="actioncotees">
          <label class="form-check-label" for="actioncotees"> Action cotées </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="actionnoncotees" v-model="actionnoncotees">
          <label class="form-check-label" for="actionnoncotees"> Action non cotées </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="OPCVMAction" v-model="OPCVMAction">
          <label class="form-check-label" for="OPCVMAction"> OPCVM Action </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="OPCVMObligataires" v-model="OPCVMObligataires">
          <label class="form-check-label" for="OPCVMObligataires"> OPCVM Obligataires </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="OPCVMMonetaires" v-model="OPCVMMonetaires">
          <label class="form-check-label" for="OPCVMMonetaires"> OPCVM Monétaires </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="FCPI" v-model="FCPI">
          <label class="form-check-label" for="FCPI"> FCPI </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="FCPR" v-model="FCPR">
          <label class="form-check-label" for="FCPR"> FCPR </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="FCPRallege" v-model="FCPRallege">
          <label class="form-check-label" for="FCPRallege"> FCPR allégé</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="FIP" v-model="FIP">
          <label class="form-check-label" for="FIP"> FIP </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="SCPI" v-model="SCPI">
          <label class="form-check-label" for="SCPI"> SCPI </label>
        </div>





        <br />
        <div class="form-floating mb-4">
          <input id="nboperation" v-model="nboperation" type="number" class="form-control" placeholder="Combien d’opérations avez-vous réalisées ces 5 dernières années ?  ">
          <label for="nboperation">Combien d’opérations avez-vous réalisées ces 5 dernières années ?</label>
        </div>


        <div class="form-floating mb-4">
          <input id="montant" v-model="montant" type="number" class="form-control" placeholder="Quel est le montant de vos investissements sur cette période ?  ">
          <label for="montant">Quel est le montant de vos investissements sur cette période ?</label>
        </div>

        <h4>VOTRE SITUATION FISCALE ET PATRIMONIALE</h4>
        Etes-vous résident fiscal français ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="residentfrance" v-model="residentfrance">
          <label class="form-check-label" for="averti">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="residentfrance" v-model="residentfrance">
          <label class="form-check-label" for="averti">NON</label>
        </div>

        <br />
        <b> Vos revenus et charges </b>
        <br /> <br />
        Quel est le montant de vos revenus individuels nets annuels ?
        <div class="form-select-wrapper">
          <select class="form-select" aria-label="Votre situation professionnelle ?" v-model="revenus" id="revenus">
            <option value="10 000 - 25 000">10 000 € à 25 000 €</option>
            <option value="25 000 - 74 000">25 000 € à 74 000 €</option>
            <option value="74 000 - 158 000">74 000 € à 158 000 €</option>
            <option value="> 158 000">&gt; à 158 000 €</option>
          </select>
        </div>

        <br />
        <div class="form-floating mb-4">
          <input id="chargesannuelles" v-model="chargesannuelles" type="number" class="form-control" placeholder="Quel est le montant de vos charges annuelles ?">
          <label for="chargesannuelles">Quel est le montant de vos charges annuelles ?</label>
        </div>



        A quel montant évaluez-vous votre capacité d'épargne annuelle ?
        <select class="form-select " required="required" v-model="epargne" id="epargne">
          <option value=""></option>
          <option value="0">0 €</option>
          <option value="5 000 - 10 000">5 000 € à 10 000 €</option>
          <option value="10 000 - 20 000">10 000 € à 20 000 €</option>
          <option value="20 000 - 40 000">20 000 € à 40 000 €</option>
          <option value="40 000 - 80 000">40 000 € à 80 000 €</option>
          <option value="80 000">&gt; à 80 000 €</option>
        </select>

        <br />
        A quel montant votre patrimoine global peut-il être évalué?
        <select class="form-select " required="required" v-model="patrimoine" id="patrimoine">
          <option value=""></option>
          <option value="0 - 50 000">0 € à 50 000 €</option>
          <option value="50 000 - 200 000">50 000 € à 200 000 €</option>
          <option value="200 000 - 400 000">200 000 € à 400 000 €</option>
          <option value="400 000 - 800 000">400 000 € à 800 000 €</option>
          <option value="800 000">&gt; à 800 000 €</option>
        </select>
        <br />
        Vos revenus proviennent essentiellement de:
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="salaires" v-model="salaires">
          <label class="form-check-label" for="salaires"> Salaires </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="pensions" v-model="pensions">
          <label class="form-check-label" for="pensions"> Pensions, retraites, rentes reçues </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="revenusfonciers" v-model="revenusfonciers">
          <label class="form-check-label" for="revenusfonciers"> Revenus fonciers </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="yes" id="revenusautres" v-model="revenusautres">
          <label class="form-check-label" for="revenusautres"> Autres </label>
        </div>


        <br />
        <b> Comment se répartit votre patrimoine global en % ? </b>
        <br />




        <br />
        <div class="form-floating mb-4">
          <input id="immobilierpersonnel" v-model="immobilierpersonnel" type="number" class="form-control" placeholder="Immobilier pour usage personnel y compris parts de SCI (en %)">
          <label for="immobilierpersonnel">Immobilier pour usage personnel y compris parts de SCI (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="investissementlocatif" v-model="investissementlocatif" type="number" class="form-control" placeholder="Immobilier de type investissement locatif y compris parts de SCI (en %)">
          <label for="investissementlocatif">Immobilier de type investissement locatif y compris parts de SCI (en %) </label>
        </div>



        <div class="form-floating mb-4">
          <input id="titresfinanciers" v-model="titresfinanciers" type="number" class="form-control" placeholder="Titres financiers émis par des sociétés cotées ou parts d’OPC (en %)">
          <label for="titresfinanciers">Titres financiers émis par des sociétés cotées ou parts d’OPC (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="actionsemises" v-model="actionsemises" type="number" class="form-control" placeholder="Parts ou actions émises par des sociétés non cotées (en %)">
          <label for="actionsemises">Parts ou actions émises par des sociétés non cotées (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="liquidite" v-model="liquidite" type="number" class="form-control" placeholder="Liquidité ou trésorerie (en %)">
          <label for="liquidite">Liquidité ou trésorerie (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="assurance" v-model="assurance" type="number" class="form-control" placeholder="Contrats d’assurance (vie, décès, capitalisation) (en %)">
          <label for="assurance">Contrats d’assurance (vie, décès, capitalisation) (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="autresplacements" v-model="autresplacements" type="number" class="form-control" placeholder="Autres placements : Livret A, PEL, LDD, etc ... (en %)">
          <label for="autresplacements">Autres placements : Livret A, PEL, LDD, etc ... (en %)</label>
        </div>



        <div class="form-floating mb-4">
          <input id="empruntimmobilier" v-model="empruntimmobilier" type="number" class="form-control" placeholder="Emprunt(s) immobilier(s) (en montant) :">
          <label for="empruntimmobilier">Emprunt(s) immobilier(s) (en montant) </label>
        </div>



        <div class="form-floating mb-4">
          <input id="autredettes" v-model="autredettes" type="number" class="form-control" placeholder="Autres dettes (en montant) ">
          <label for="autredettes">Autres dettes (en montant) </label>
        </div>


        Etes-vous assujetti à l’IFI ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" v-model="IFI">
          <label class="form-check-label" for="IFI">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" v-model="IFI">
          <label class="form-check-label" for="IFI">NON</label>
        </div>

        <br /> Attention, l’investissement dans des sociétés non cotées comporte des risques spécifiques :
        <br /> · Risque de perte totale ou partielle du capital investi
        <br /> · Risque d’illiquidité : la revente des titres n’est pas garantie, elle peut être incertaine voire impossible
        <br /> · Le retour sur investissement dépend de la réussite du projet financé
        <br />
        <br /> Avez-vous conscience que vous pouvez perdre éventuellement la totalité de votre investissement ?
        <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="perdre" v-model="perdre">
          <label class="form-check-label" for="perdre">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="perdre" v-model="perdre">
          <label class="form-check-label" for="perdre">NON</label>
        </div>
        <br />
        Avez-vous conscience que vous aurez des difficultés à revendre vos titres ? <div class="form-check">
          <input class="form-check-input" type="radio" value="yes" id="difficultes" v-model="difficultes">
          <label class="form-check-label" for="difficultes">OUI</label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" value="no" id="difficultes" v-model="difficultes">
          <label class="form-check-label" for="difficultes">NON</label>
        </div>

        <p> <br />
          Les informations recueillies dans le cadre du questionnaire d’adéquation font l’objet d’un traitement informatique automatisé dont l’utilisation et la conservation sont en conformité avec les dispositions de la loi informatique et liberté
          n°78-17 du 6 janvier 1978 modifiée. Ce questionnaire s’inscrit dans le cadre des dispositions des articles L.547-9 du Code monétaire et financier et 325-32 du Règlement général de l’AMF. Ces informations sont communiquées à l’usage exclusif
          de la plateforme et sont couvertes par le secret professionnel. Elles ne pourront être communiquées à des tiers que dans des cas limitatifs prévus par la loi (autorité judiciaire ou autorité administrative de tutelle telle que l’Autorité
          des marchés financiers). Néanmoins, vous autorisez la plateforme à communiquer ces informations à toute personne à des fins de traitement technique ou administratif. Enfin, vous pouvez vous opposer à ce que ces données fassent l’objet d’un
          traitement et disposez d’un droit d’accès et de rectification de celles-ci. Vous pouvez adresser votre demande à la plateforme
        </p>
        <input class="form-check-input" type="checkbox" value="yes" id="confirm" v-model="confirm"> Je déclare assurer l’entière responsabilité des réponses que j’ai apportées à ce questionnaire et certifie l’exactitude de celles-ci.
        <br /><br />
        <a class="btn btn-success" @click="form_profil">Valider</a>
      </div>
      <div class="tab-pane fade" id="tab5">
        <p>
          La collecte de ces documents justificatifs permettra de finaliser l'ouverture de votre compte de paiement nécessaire à la réalisation de vos opérations.
        <h5>POINTS A VERIFIER IMPERATIVEMENT</h5>

        - Vérifier la date d'expiration de la pièce d'identité, le document doit être lisible et un seul document recto-verso doit être téléchargé.
        <br /> - La pièce fournie doit être celle du mandataire social ou dirigeant
        <br /> - Le nom et le prénom (<b class="text-red">{{name}} {{firstname}}</b>) saisi dans l'onglet DONNEES PERSONNELLES doit correspondent au nom et prénom de la pièce d'identité et du justificatif de domicile.
        <br />- le numéro IBAN saisi doit correspondre à l'IBAN de la pièce-justificative chargée.
        </p>
        <b for="avatar">Document d'identité </b>





        <v-file-input show-size counter accept="image/png, image/jpeg , application/pdf" :placeholder="'Passeport ou carte identité :'+file_ci" @blur="blur('file_ci')" @change="upload"> </v-file-input>



        <div class="form-floating mb-4">
          <input v-model="datevalide" id="datevalide" name="datevalide" type="date" class="form-control" placeholder="Date de validité du document">
          <label for="datevalide">Date de validité du document</label>
        </div>
        <br />
        <b for="avatar">Justificatif de domicile</b>


        <v-file-input show-size counter accept="image/png, image/jpeg , application/pdf" :placeholder="'justificatif de domicile: '+file_dom" @blur="blur('file_dom')" @change="upload" prepend-icon="mdi-paperclip"> </v-file-input>

        <br /><br />
        <b for="avatar">RIB (Relevé d'identité Bancaire)</b>
        <v-file-input show-size counter accept="image/png, image/jpeg , application/pdf" :placeholder="'Iban: '+file_iban" @blur="blur('file_rib')" @change="upload" prepend-icon="mdi-paperclip"> </v-file-input>


        <div class="form-floating mb-4">
          <input v-model="iban" id="iban" name="iban" type="iban" class="form-control" placeholder="IBAN">
          <br /> <label for="iban">IBAN</label>

          <br /><br /> <a class="btn btn-success" @click="form_justif">Valider</a>
        </div>

      </div>


      <!--/.tab-pane -->
    </div>
    <!-- /.tab-content -->
  </div>
</section>
</template>


<script>
const axios = require("axios");

export default {
  name: 'tabs1',
  components: {},
  props: {},
  data: () => ({
    key: '',
    opt: [],
    axios_private: [],

    situationfamille: '',
    profession: '',
    nationalite: '',
    villenaissance: '',
    departementnaissance: '',
    datenaissance: '',
    name: '',
    firstname: '',
    civilite: '',
    residencefiscale: '',

    profil: '',
    siren: '',
    numerorue: '',
    voie: '',
    complement1: '',
    complement2: '',
    codepostal: '',
    ville: '',
    pays: '',
    telephone: '',
    email: '',
    password: '',

    actioncotees: '',
    actionnoncotees: '',
    actionsemises: '',
    assurance: '',
    autredettes: '',
    autres: '',
    autresplacements: '',
    avantagesfiscaux: '',
    averti: '',
    chargesannuelles: '',
    confirm: '',
    constituerepargne: '',
    difficultes: '',
    diversifierinvestissements: '',
    dividende: '',
    empruntimmobilier: '',
    enfant: '',
    epargne: '',
    FCPI: '',
    FCPR: '',
    FCPRallege: '',
    fichieramf: '',
    FIP: '',
    fonctionsfinance: '',
    horizon: '',
    IFI: '',
    immobilierpersonnel: '',
    investissementlocatif: '',
    liquidite: '',
    montant: '',
    nboperation: '',
    OPCVMAction: '',
    OPCVMMonetaires: '',
    OPCVMObligataires: '',
    patrimoine: '',
    pensions: '',
    perdre: '',
    preparerretraire: '',
    residentfrance: '',
    revenus: '',
    revenusautres: '',
    revenusfonciers: '',
    revenusreguliers: '',
    risqueobligation: '',
    salaires: '',
    SCPI: '',
    situationpro: '',
    titresfinanciers: '',
    valorisercapital: '',

    datevalide: '',
    iban: '',

    file: '',
    file_extension: '',
    icone_file_extension: "https://lecercle7.com/img/plus.svg",
    image_serveur: [],
    origin_upload: '',
    file_ci: '',
    file_iban: '',
    file_dom: '',
  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {

    this.key = this.getKey();

    if (!this.key) this.key = Math.random().toString(36).substring(0, 25) + Math.random().toString(36).substring(0, 25).replace('.', '');
    else
      this.charge_data()

  },
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {
    charge_data() {
      this.opt = [];
      this.opt["api"] = "data_perso"
      this.opt["key"] = this.key
      this.opt["debug"] = false
      this.charge_private();

      this.opt = [];
      this.opt["api"] = "data_contact"
      this.opt["key"] = this.key
      this.opt["debug"] = false
      this.charge_private();

      this.opt = [];
      this.opt["api"] = "data_profil"
      this.opt["key"] = this.key
      this.opt["debug"] = false
      this.charge_private();

      this.opt = [];
      this.opt["api"] = "data_justif"
      this.opt["key"] = this.key
      this.opt["debug"] = false
      this.charge_private();
    },
    charge_private() {
      this.$store.dispatch('post', this.opt).then(response => {
        if (response != undefined) this.axios_private = response.data
      })
    },

    form_connect() {
      this.opt = [];
      this.opt["api"] = "form_connect"
      this.opt["debug"] = true
      this.opt["telephone"] = this.telephone
      this.opt["email"] = this.email
      this.opt["password"] = this.password
      this.charge_private();
    },
    form_justif() {


      this.opt = [];
      this.opt["api"] = "form_justif"
      this.opt["debug"] = false
      this.opt["key"] = this.key
      this.opt["datevalide"] = this.datevalide
      this.opt["iban"] = this.iban
      this.charge_private();
    },
    form_perso() {


      this.opt = [];
      this.opt["api"] = "form_perso"
      this.opt["key"] = this.key
      //  this.opt["debug"] = true
      this.opt["civilite"] = this.civilite
      this.opt["firstname"] = this.firstname
      this.opt["name"] = this.name
      this.opt["profession"] = this.profession
      this.opt["situationfamille"] = this.situationfamille;
      this.opt["datenaissance"] = this.datenaissance
      this.opt["villenaissance"] = this.villenaissance
      this.opt["departementnaissance"] = this.departementnaissance
      this.opt["nationalite"] = this.nationalite
      this.opt["residencefiscale"] = this.residencefiscale
      this.charge_private();
    },

    form_contact() {
      this.opt = [];
      this.opt["api"] = "form_contact"
      this.opt["key"] = this.key
      //    this.opt["debug"] = true
      this.opt["profil"] = this.profil
      this.opt["siren"] = this.siren
      this.opt["numerorue"] = this.numerorue
      this.opt["voie"] = this.voie
      this.opt["complement1"] = this.complement1;
      this.opt["complement2"] = this.complement2
      this.opt["codepostal"] = this.codepostal
      this.opt["ville"] = this.ville
      this.opt["pays"] = this.pays
      this.opt["telephone"] = this.telephone
      this.opt["email"] = this.email
      this.opt["password"] = this.password
      this.charge_private();
    },
    form_profil() {
      this.opt = [];
      this.opt["api"] = "form_profil"
      this.opt["key"] = this.key
      this.opt["debug"] = true
      this.opt["actioncotees"] = this.actioncotees
      this.opt["actionnoncotees"] = this.actionnoncotees
      this.opt["actionsemises"] = this.actionsemises
      this.opt["assurance"] = this.assurance
      this.opt["autredettes"] = this.autredettes
      this.opt["autres"] = this.autres
      this.opt["autresplacements"] = this.autresplacements
      this.opt["avantagesfiscaux"] = this.avantagesfiscaux
      this.opt["averti"] = this.averti
      this.opt["chargesannuelles"] = this.chargesannuelles
      this.opt["confirm"] = this.confirm
      this.opt["constituerepargne"] = this.constituerepargne
      this.opt["difficultes"] = this.difficultes
      this.opt["diversifierinvestissements"] = this.diversifierinvestissements
      this.opt["dividende"] = this.dividende
      this.opt["empruntimmobilier"] = this.empruntimmobilier
      this.opt["enfant"] = this.enfant
      this.opt["epargne"] = this.epargne

      this.opt["FCPI"] = this.FCPI
      this.opt["FCPR"] = this.FCPR
      this.opt["FCPRallege"] = this.FCPRallege
      this.opt["fichieramf"] = this.fichieramf
      this.opt["FIP"] = this.FIP
      this.opt["fonctionsfinance"] = this.fonctionsfinance
      this.opt["horizon"] = this.horizon
      this.opt["IFI"] = this.IFI
      this.opt["immobilierpersonnel"] = this.immobilierpersonnel
      this.opt["investissementlocatif"] = this.investissementlocatif
      this.opt["liquidite"] = this.liquidite
      this.opt["montant"] = this.montant
      this.opt["nboperation"] = this.nboperation
      this.opt["OPCVMAction"] = this.OPCVMAction
      this.opt["OPCVMMonetaires"] = this.OPCVMMonetaires
      this.opt["OPCVMObligataires"] = this.OPCVMObligataires
      this.opt["patrimoine"] = this.patrimoine
      this.opt["pensions"] = this.pensions
      this.opt["perdre"] = this.perdre
      this.opt["preparerretraire"] = this.preparerretraire
      this.opt["residentfrance"] = this.residentfrance
      this.opt["revenus"] = this.revenus
      this.opt["revenusautres"] = this.revenusautres
      this.opt["revenusfonciers"] = this.revenusfonciers
      this.opt["revenusreguliers"] = this.revenusreguliers
      this.opt["risqueobligation"] = this.risqueobligation
      this.opt["salaires"] = this.salaires
      this.opt["SCPI"] = this.SCPI
      this.opt["situationpro"] = this.situationpro
      this.opt["titresfinanciers"] = this.titresfinanciers
      this.opt["valorisercapital"] = this.valorisercapital
      this.charge_private();
    },
    blur: function(origin) {
      file_iban
      this.origin_upload = origin;
    },
    upload: function(file) {
      this.file = file.name;
      //    this.file_extension= this.extention(file.name) ;
      console.log(this.file)
      console.log(this.file_extension)
      console.log(origin)
      let data = new FormData();
      data.append('file', file);
      data.append('key', this.key);
      data.append('origin', this.origin_upload);

      axios.post('https://api.berlcoin.fr/post_upload.php',
          data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(
          response => {
            this.image_serveur = response.data
          }
        )
        .catch(function() {
          console.log('FAILURE!!');
        });
    },
    extention: function(filename) {
      return filename.split('.').pop();
    },


  },
  watch: {
    image_serveur: function() {
      console.log(this.image_serveur)
    },
    axios_private: function() {

      if (this.axios_private != undefined) {
      //  console.log(this.axios_private);
        if (this.axios_private.result == true) this.saveFile(this.key)

        if (this.axios_private.result == "connect") {
          if (this.axios_private.data != null) {
            this.key = this.axios_private.data.key

            this.charge_data();
          }
        }

        if (this.axios_private.result == "perso") {
          if (this.axios_private.data != null) {
            this.situationfamille = this.axios_private.data.situationfamille;
            this.profession = this.axios_private.data.profession;
            this.nationalite = this.axios_private.data.nationalite;
            this.villenaissance = this.axios_private.data.villenaissance;
            this.departementnaissance = this.axios_private.data.departementnaissance;
            this.datenaissance = this.axios_private.data.datenaissance;
            this.name = this.axios_private.data.name;
            this.firstname = this.axios_private.data.firstname;
            this.civilite = this.axios_private.data.civilite;
            this.residencefiscale = this.axios_private.data.residencefiscale;
          }
        }
        if (this.axios_private.result == "contact") {
          if (this.axios_private.data != null) {
            this.siren = this.axios_private.data.siren;
            this.profil = this.axios_private.data.profil;
            this.numerorue = this.axios_private.data.numerorue;
            this.voie = this.axios_private.data.voie;
            this.complement1 = this.axios_private.data.complement1;
            this.complement2 = this.axios_private.data.complement2;
            this.codepostal = this.axios_private.data.codepostal;
            this.ville = this.axios_private.data.ville;
            this.pays = this.axios_private.data.pays;
            this.telephone = this.axios_private.data.telephone;
            this.email = this.axios_private.data.email;
            this.password = this.axios_private.data.password
          }
        }
        if (this.axios_private.result == "profil") {
          if (this.axios_private.data != null) {
            this.actioncotees = this.axios_private.data.actioncotees
            this.actionnoncotees = this.axios_private.data.actionnoncotees
            this.actionsemises = this.axios_private.data.actionsemises
            this.assurance = this.axios_private.data.assurance
            this.autredettes = this.axios_private.data.autredettes
            this.autres = this.axios_private.data.autres
            this.autresplacements = this.axios_private.data.autresplacements
            this.avantagesfiscaux = this.axios_private.data.avantagesfiscaux
            this.averti = this.axios_private.data.averti
            this.chargesannuelles = this.axios_private.data.chargesannuelles
            this.confirm = this.axios_private.data.confirm
            this.constituerepargne = this.axios_private.data.constituerepargne
            this.difficultes = this.axios_private.data.difficultes
            this.diversifierinvestissements = this.axios_private.data.diversifierinvestissements
            this.dividende = this.axios_private.data.dividende
            this.empruntimmobilier = this.axios_private.data.empruntimmobilier
            this.enfant = this.axios_private.data.enfant
            this.epargne = this.axios_private.data.epargne

            this.FCPI = this.axios_private.data.FCPI
            this.FCPR = this.axios_private.data.FCPR
            this.FCPRallege = this.axios_private.data.FCPRallege
            this.fichieramf = this.axios_private.data.fichieramf
            this.FIP = this.axios_private.data.FIP
            this.fonctionsfinance = this.axios_private.data.fonctionsfinance
            this.horizon = this.axios_private.data.horizon
            this.IFI = this.axios_private.data.IFI
            this.immobilierpersonnel = this.axios_private.data.immobilierpersonnel
            this.investissementlocatif = this.axios_private.data.investissementlocatif
            this.liquidite = this.axios_private.data.liquidite
            this.montant = this.axios_private.data.montant
            this.nboperation = this.axios_private.data.nboperation
            this.OPCVMAction = this.axios_private.data.OPCVMAction
            this.OPCVMMonetaires = this.axios_private.data.OPCVMMonetaires
            this.OPCVMObligataires = this.axios_private.data.OPCVMObligataires
            this.patrimoine = this.axios_private.data.patrimoine
            this.pensions = this.axios_private.data.pensions
            this.perdre = this.axios_private.data.perdre
            this.preparerretraire = this.axios_private.data.preparerretraire
            this.residentfrance = this.axios_private.data.residentfrance
            this.revenus = this.axios_private.data.revenus
            this.revenusautres = this.axios_private.data.revenusautres
            this.revenusfonciers = this.axios_private.data.revenusfonciers
            this.revenusreguliers = this.axios_private.data.revenusreguliers
            this.risqueobligation = this.axios_private.data.risqueobligation
            this.salaires = this.axios_private.data.salaires
            this.SCPI = this.axios_private.data.SCPI
            this.situationpro = this.axios_private.data.situationpro
            this.titresfinanciers = this.axios_private.data.titresfinanciers
            this.valorisercapital = this.axios_private.data.valorisercapital
          }
        }
        if (this.axios_private.result == "justif") {
          if (this.axios_private.data != null) {
            this.datevalide = this.axios_private.data.datevalide
            this.iban = this.axios_private.data.iban
            this.file_ci = this.axios_private.data.file_ci
            this.file_dom = this.axios_private.data.file_dom
            this.file_iban = this.axios_private.data.file_rib
            this.file_iban = this.file_iban.substring(this.file_iban.indexOf("/") + 1, 999)
            this.file_ci = this.file_ci.substring(this.file_ci.indexOf("/") + 1, 999)
            this.file_dom = this.file_dom.substring(this.file_dom.indexOf("/") + 1, 999)

          }
        }
      }
    },
  }
}
</script>

<style scoped>
</style>
