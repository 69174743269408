<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center">
			<div class="col-lg-6 position-relative">
				<div class="shape bg-line leaf rounded-circle rellax w-17 h-17"
						 data-rellax-speed="1"
						 style="top: -2rem; right: -0.6rem;"></div>
				<div class="shape bg-pale-violet rounded-circle rellax w-17 h-17"
						 data-rellax-speed="1"
						 style="bottom: -2rem; left: -0.4rem;"></div>
				<figure class="rounded mb-0"><img src="@/assets/media/ICO-AMF.png"
							 srcset="@/assets/media/ICO-AMF.png"
							 alt=""></figure>
			</div>
			<!--/column -->
			<div class="col-lg-6">
				<h3 class="display-4 mb-4">Security Token Offerings</h3>
				<p class="mb-5">Participer à l'offre initiale de Berlcoin et bénéficiez de conditions d'entrées avantageuses. </p>
				<div class="row gy-3">
					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-leaf mb-0">
							<li><span><i class="uil uil-check"></i></span><span>Token à cours initial de 100 € durant toute la période de la STO</span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>Récompenses des contrats à terme supérieure à l'offre publique. </span></li>
						</ul>
					</div>
					<!--/column -->
					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-leaf mb-0">
							<li><span><i class="uil uil-check"></i></span><span>Droit de préemption sur un bien immobilier.</span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>Priorité sur les offres publiques après la période de la STO</span></li>
						</ul>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->

			</div>
			<!--/column -->
		</div>
		<!--/.row -->
		<div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
			<div class="col-lg-6 order-lg-2 position-relative">
				<div class="shape bg-line aqua rounded-circle rellax w-17 h-17"
						 data-rellax-speed="1"
						 style="top: -2rem; left: -0.6rem;"></div>
				<div class="shape bg-pale-red rounded-circle rellax w-17 h-17"
						 data-rellax-speed="1"
						 style="bottom: -2rem; right: -0.4rem;"></div>
				<figure class="rounded mb-0"><img src="@/assets/media/f2.jpg"
							 srcset="@/assets/media/f2.jpg"
							 alt=""></figure>
			</div>
			<!--/column -->
			<div class="col-lg-6">
				<h3 class="display-4 mb-4">STO vs Offre publique</h3>
				<p class="mb-5">A la différence des contrats à terme publics, la vente des tokens de la STO finance des biens immobiliers qui n'ont pas encore été achetés.
					<br /><br /> Les achats de biens immobiliers se feront à postériori, c'est une différence très importante : au moment de l'émission les tokens de la STO n'ont pas encore de valeur garantie.
					<br /><br /> Par contre, quelle que soit la durée du contrat à terme 'STO', vous bénéficiez de la même garantie qu'un contrat patrimoine : les berlcoins que vous achetez seront directement liés à un bien immobilier physique.
				</p>

				<!--/.row -->
				<a href="#"
					 class="btn btn-soft-aqua rounded-pill mt-6 mb-0">Livre blanc bientôt disponible</a>
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
		<div class="container py-14 py-md-16">
			<div class="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center">
				<div class="col-lg-12">
					<div class="card">

						<div class="card-body">
							<h4>Différence de récompenses entre les contrats STO et les contrats publics proposés après le 1 juillet 2022</h4>
							<p class="lead">
								Les récompenses correspondent à l'effet de levier bancaire, intégralement répercuté de façon linéaire sur les contrats STO.</p>
							<p> Le prix est de 100 € le token sans variation durant la période de la STO.
								<br /><br />La valeur de référence du token sera recalculé à l'issue de la période da STO en fonction des acquisitions. Les contrats STO commencent 6 mois après leur signature pour la durée du contrat sélectionnée quelle que soit la
								date de signature.
								<br /><br />10 000 tokens à 100 € pour une valeur totale d'un million d'euros seront mis sur le marché secondaire, garanti par trois biens immobiliers faisant partie du patrimoine de la société de gestion durant la période de la STO.

								<a href="Property"
									 class="alert-link hover">
									Les biens immobiliers en garantie</a>
								<br /><br /> La STO prend fin le 1 juillet 2022 ou lorsque 100 000 tokens auront été émis.
							</p>
							<table class="table  table-bordered table-striped">
								<tr>
									<td class="py-4"> Durée du contrat</td>
									<td> Contrat STO</td>
									<td> Contrat public</td>
								</tr>

								<tr>
									<td class="py-4"> 1 an</td>
									<td> 4,32% </td>
									<td>- </td>
								</tr>
								<tr>
									<td class="py-4"> 2 ans</td>
									<td> 8,71%</td>
									<td> 1,90%</td>
								</tr>
								<tr>
									<td class="py-4"> 3 ans</td>
									<td> 13,17%</td>
									<td> 5,60%</td>
								</tr>
								<tr>
									<td class="py-4"> 4 ans</td>
									<td>17,69% </td>
									<td></td>
								</tr>
								<tr>
									<td class="py-4"> 5 ans</td>
									<td> 22,28%</td>
									<td>14,40%</td>
								</tr>
								<tr>
									<td class="py-4"> 6 ans</td>
									<td> 26,94% </td>
									<td></td>
								</tr>
								<tr>
									<td class="py-4"> 7 ans</td>
									<td> 31,67%</td>
									<td> 28,50%</td>
								</tr>
								<tr>
									<td class="py-4"> 8 ans </td>
									<td>36,47% </td>
									<td></td>
								</tr>
								<tr>
									<td class="py-4"> 9 ans</td>
									<td> 41,34% </td>
									<td></td>
								</tr>
								<tr>
									<td class="py-4"> 10 ans </td>
									<td>46,28% *</td>
									<td> 56,00%</td>
								</tr>
							</table>
							<i style="font-size:80%"> Les contrats patrimoine de 10 ans bénéficient d'un sur-effet de levier bancaire qui est financé par le différentiel des contrats de moindre durée dans la limite de l'effet de levier global.
								Pour la STO, tous les contrats bénéficient de l'intégralité de l'effet de levier, il n'y a donc pas de financement possible de la surprime du contrat patrimoine, nous appliquons donc le taux réel mais avec le cours du token à 100
								euros.
							</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'feature4',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
