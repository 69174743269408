<template>
<section class="wrapper bg-light">
	<div class="container py-4 py-md-4">
		<div class="  mx-auto">
			<div class="alert alert-success alert-icon text-center"
					 role="alert">
				<a href="https://wallet.fraction.re"
					 style="font-size:75%"
					 class="alert-link hover"> {{$t('invest1')}} <br /> {{$t('invest2')}}

				</a>

			</div>

			<a href="https://berlcoin.market">
				<action2 :titre="'<b style=\'font-size:350%; text-align:center;width:100%\'>6.1%</b><br/>    2022<br/><br/>'+$t('invest3') "
								 :soustitre="$t('invest3')" />
			</a>

			<v-card elevation="0"
							v-show="false"
							class="mt-4 mb-8"
							v-if="step==1">

				<h3 class="pt-3"
						:style="detail.solde > 0 ? 'color:green' : 'color:red'"
						v-html="' &nbsp; '+$t('invest3')" />
				<p class="mx-2"> <a href="https://wallet.fraction.re/identity"> {{$t('invest4')}}</a>
					<br /> {{$t('goodtoknow')}}
				</p>

				<v-card-title>{{$t('invest5')}}</v-card-title>
				<v-card-text>
					<table style="width:100%">
						<tr>
							<td>{{$t('invest6')}} <br /> <b>{{detail.date_maj}}</b></td>
							<td>{{$t('invest7')}} <br /><b v-html="monetary_aspect(parseFloat(detail.valeur_maj))" /></td>
						</tr>
						<tr>
							<td>{{$t('invest8')}}<br /><b v-html="monetary_aspect(detail.anticipation)" /></td>
							<td>{{$t('invest9')}}<br /> <b v-html="monetary_aspect(price)" /></td>
						</tr>
					</table>
				</v-card-text>

				<hr class="my-2" />
				<v-card-title>{{$t('invest10')}}</v-card-title>
				<v-card-text>
					<v-text-field v-model="email"
												style="font-size:60%"
												persistent-hint
												:hint="$t('invest12')"
												:label="$t('invest11')">
					</v-text-field>

					<v-text-field v-model="clepublique"
												:rules="[rules.counter]"
												style="font-size:60%"
												persistent-hint
												:hint="$t('invest14')"
												:label="$t('invest13')">
					</v-text-field>
					<br />
					<v-text-field v-model="field_euro"
												outlined
												@blur="convert_berlcoin"
												:label="$t('invest15')"></v-text-field>
					{{$t('invest16')}} <b>{{quantity}}</b> Berlcoin
				</v-card-text>
				<hr class="my-0" />
				<v-card-title> {{$t('invest17')}} </v-card-title>
				<v-card-text>

					<v-chip-group v-model="term"
												active-class="deep-purple--text text--accent-4"
												mandatory>
						<v-chip v-for="size in sizes"
										:key="size"
										:dark="size !=term ? true : false"
										:value="size">
							{{ size }}
						</v-chip>
					</v-chip-group>
					<p class="caption mt-5 sucess--text"
						 style="font-size:80%">
						{{$t('invest18')}} <b class="red--text">{{credit}}</b> <code>{{asset_code}}</code>
						<br /> {{$t('invest19')}} <b class="red--text">{{reward}}</b> <code>{{asset_code}}</code>
						<br /> {{$t('invest20')}} <b class="text-red">{{total}}</b> <code>{{asset_code}}</code>

						<br />{{$t('invest21')}}<b class="success--text">{{win}}</b> <code>{{asset_code_fceuro}} </code>
						<br> {{$t('invest22')}} <b>{{term> 0 ? '   '+term+' '+$t('invest40') : $t('invest39')}}</b>
					</p>

				</v-card-text>

				<v-card-title> {{$t('invest23')}}</v-card-title>
				<v-card-text>
					{{$t('invest24')}}
					<v-otp-input v-model="naissance"
											 length="4"></v-otp-input>
					<i style="font-size:80%">{{$t('invest34')}}</i>
				</v-card-text>


				<div v-if="detail.solde > 0 ">

					<v-card-actions>

						<div @click="souscrire()"
								 v-if="(clepublique.length == 56 || email != '')  && naissance.length ==4"
								 style="background-color:green; color:white"
								 class="btn  success mx-auto">{{$t('invest41')}}</div>

						<a style="background-color:purple; color:white"
							 href="https://wallet.fraction.re/identity"
							 v-else
							 class="btn m-auto py-1 mb-3  "> {{$t('invest29')}}</a>

						<a :href="'/'+$i18n.locale+'/sell'"
							 style="background-color:orange; color:white"
							 class="btn m-auto py-1 mb-3 ">{{$t('invest44')}}</a>

					</v-card-actions>
				</div>
			</v-card>




			<v-card class="mx-auto mt-1 mb-8 p-3"
							v-if="step==2"
							max-width="450">
				<h3 v-html="$t('invest45')" />
				<p v-html="$t('invest46')" />
				<p v-html="$t('invest47')" />
				<p>
					<br />
					<br /> <b> {{ detail.raison_sociale}}</b>
					<br /> {{ detail.ppadresse}}
					{{ detail.ppcode_postal}} {{ detail.ppville}}
					<br />Siren : {{ detail.siren}}
				</p>
				<p v-html="$t('invest48')" />
				<v-card-actions>
					<a class="btn mt-8 mb-5 mx-auto"
						 style="background-color:green;color:white"
						 href="/investir"
						 v-html="$t('invest49')" />
				</v-card-actions>

			</v-card>



			<div class="my-4"
					 style="height:100%">
				<h2 class="mb-12">{{$t('invest35')}}</h2>
				<blockimage :bt1="$t('invest25')"
										:bt3="$t('invest32')"
										:bt2="$t('invest29')"
										bt4=" "
										:bx1="$t('invest26')+'<br/><br/>'+$t('invest27') "
										:bx2="$t('invest30')"
										:bx3="$t('invest33')"
										bx4=""
										bi1="https://media.berlcoin.fr/sc1.jpg"
										bi3="https://media.berlcoin.fr/sc2.jpg"
										bi2="https://media.berlcoin.fr/signature.jpg"
										bi4=""
										link1="open"
										link3="wallet"
										link2="francais"
										link4="" />
			</div>

			<h4 class="h2 mt-4 mb-8">{{$t('invest36')}}</h4>
			<table class="table table-bordered">
				<tr>
					<td> {{ $t('invest40')}} </td>
					<td>{{ $t('invest43')}}</td>
					<td> {{ $t('invest42')}} </td>
				</tr>
				<tr>
					<td> 2015</td>
					<td> 1 </td>
					<td>9,1 %</td>
				</tr>
				<tr>
					<td> 2015</td>
					<td> 2</td>
					<td> 11,4 %</td>
				</tr>
				<tr>
					<td> 2015</td>
					<td> 3 </td>
					<td>11,2 %</td>
				</tr>
				<tr>
					<td> 2015</td>
					<td> 4</td>
					<td> 12,1 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2016</td>
					<td> 1 </td>
					<td>11,8 %</td>
				</tr>
				<tr>
					<td> 2016</td>
					<td> 2 </td>
					<td>10,3 %</td>
				</tr>
				<tr>
					<td> 2016</td>
					<td> 3</td>
					<td> 12,3 %</td>
				</tr>
				<tr>
					<td> 2016</td>
					<td> 4 </td>
					<td>12,0 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2017</td>
					<td> 1</td>
					<td> 12,4 %</td>
				</tr>
				<tr>
					<td> 2017</td>
					<td> 2 </td>
					<td>13,4 %</td>
				</tr>
				<tr>
					<td> 2017</td>
					<td> 3</td>
					<td> 14,4 %</td>
				</tr>
				<tr>
					<td> 2017</td>
					<td> 4</td>
					<td> 16,4 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2018</td>
					<td> 1</td>
					<td> 14,9 %</td>
				</tr>
				<tr>
					<td> 2018</td>
					<td> 2</td>
					<td> 12,7 %</td>
				</tr>
				<tr>
					<td> 2018</td>
					<td> 3 </td>
					<td>11,5 %</td>
				</tr>
				<tr>
					<td> 2018</td>
					<td> 4 </td>
					<td>8,0 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2019</td>
					<td> 1</td>
					<td> 6,0 %</td>
				</tr>
				<tr>
					<td> 2019</td>
					<td> 2 </td>
					<td>5,5 %</td>
				</tr>
				<tr>
					<td> 2019</td>
					<td> 3</td>
					<td> 3,8 %</td>
				</tr>
				<tr>
					<td> 2019</td>
					<td> 4</td>
					<td> 4,3 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2020 </td>
					<td>1</td>
					<td> 5,5 %</td>
				</tr>
				<tr>
					<td> 2020 </td>
					<td> 2 </td>
					<td> 5,3 %</td>
				</tr>
				<tr>
					<td> 2020 </td>
					<td> 3 </td>
					<td> 5,6 %</td>
				</tr>
				<tr>
					<td> 2020 </td>
					<td>4 </td>
					<td> 5,1 %</td>
				</tr>
				<tr>
					<td>
						<hr class="my-1 py-1" />
					</td>
				</tr>
				<tr>
					<td> 2021 </td>
					<td>1 </td>
					<td> 5,3 %</td>
				</tr>
				<tr>
					<td> 2021 </td>
					<td> 2 </td>
					<td>8,0 %</td>
				</tr>
				<tr>
					<td> 2021 </td>
					<td> 3 </td>
					<td>10,0 %</td>
				</tr>
				<tr>
					<td> 2021 </td>
					<td> 4 </td>
					<td>11,5 %</td>
				</tr>
				<tr>
					<td> 2022 </td>
					<td> 1 </td>
					<td>13,3 %</td>
				</tr>
			</table><br />
			<a target="_blank"
				 href="https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html">{{ $t('invest37')}} </a>
			<hr />
			<img style="width:100%"
					 src='https://media.berlcoin.fr/index.berlcoin.png' />
		</div>






		<div class="alert alert-success alert-icon text-center"
				 role="alert">
			<a href="Property"
				 class="alert-link hover">
				{{ $t('invest38')}} </a>
		</div>


	</div>

</section>
</template>


<script>
import feature4 from "@/components/feature4.vue";
import feature5 from "@/components/feature5.vue";
import tab1 from "@/components/tabs1.vue";
import action2 from "@/components/action2.vue";
import blockimage from "@/components/blocks_image_3b.vue";
export default {
	name: 'investir',
	components: {
		feature4,
		feature5,
		tab1,
		action2,
		blockimage
	},
	props: {},
	data: () => ({
		quantity: 0,
		price: 0,
		credit: 0,
		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		field_euro: 0,
		detail: [],
		step: 1,
		price: 0,
		asset_code: "Berlcoin",
		field_euro: 100,
		clepublique: "",
		naissance: "",
		email: '',
		asset_code_fceuro: "€",
		term: 0,
		sizes: [
			'0', '2', '3', '5', '7', '10',
		],
		rules: {
			required: value => !!value || 'Required.',
			counter: value => value.length == 56 || 'Une clé à 56 characteres',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
		},
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.$i18n.locale = this.$route.params.lang

		this.detail.ville = 'BERLIN'
		this.detail.adresse = "Zone A"
		this.detail.siren = '911 774 206'
		this.detail.raison_sociale = "Fraction Real Estate"
		this.detail.ppadresse = "56 rue monge  "
		this.detail.ppcode_postal = "75005"
		this.detail.ppville = "PARIS"
		this.detail.telephone = "+337 66 46 17 28"
		this.detail.email = "Berlcoin@fraction.re"
		this.detail.solde = "10000"
		this.detail.anticipation = "11.5"
		this.detail.date_maj = "2022-01-01"
		this.detail.valeur_maj = 100

		let date1 = new Date(this.detail.date_maj);
		let date2 = new Date();
		let Diff_temps = date2.getTime() - date1.getTime();
		let Diff_jours = Diff_temps / (1000 * 3600 * 24);


		this.price = parseFloat(this.detail.valeur_maj) + (Diff_jours * (parseFloat(this.detail.valeur_maj) * parseFloat(this.detail.anticipation) / 100) / 365);
		this.quantity = this.field_euro / this.price;

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		souscrire() {
			this.opt = [];
			this.opt["api"] = "write_contract_berlcoin"
			this.opt["debug"] = false
			this.opt["cle"] = this.clepublique
			this.opt["email"] = this.email
			this.opt["field_euro"] = this.field_euro
			this.opt["antispam"] = this.naissance
			this.opt["term"] = this.term

			this.opt["token_asset_code"] = this.id
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.step = 2;
			})

		},

		convert_berlcoin: function () {
			this.quantity = Number(this.field_euro) / Number(this.price);
			this.verif_nb_berlcoin();
		},
		verif_nb_berlcoin: function () {
			this.quantity = (Math.round(this.quantity * 100000) / 100000).toFixed(5);
			this.field_euro = (Math.round(this.field_euro * 100) / 100).toFixed(2);
			let taux = 1;

			//console.log(this.term)
			if (this.term == "0") taux = 0;
			if (this.term == "2") taux = 1.9;
			if (this.term == "3") taux = 5.6;
			if (this.term == "5") taux = 14.4;
			if (this.term == "7") taux = 28.5;
			if (this.term == "10") taux = 46;

			this.credit = this.quantity;
			this.reward = (Number(this.quantity) * taux / 100).toFixed(2);
			this.total = (Number(this.reward) + Number(this.quantity)).toFixed(2);
			this.totaleuro = (Number(this.total) * Number(this.price)).toFixed(2);
			this.win = (Number(this.reward) * Number(this.price)).toFixed(2);

		},



	},
	watch: {
		term() {
			this.convert_berlcoin();
		},
		field_euro() {
			this.quantity = this.field_euro / this.price;
			if (parseFloat(this.quantity) > parseFloat(this.detail.solde)) {
				this.field_euro = Math.floor(this.detail.solde * this.price * 100) / 100;
				this.quantity = this.detail.solde;
			}
		}
	}
}
</script>

<style scoped>
</style>
