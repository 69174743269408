<template>
<section class="  bg-light">
	<div class="container mb-8 mt-n12   ">
		<div class="row row-cols-1 row-cols-md-2 row-cols-xl-3   gx-lg-6 gy-3 justify-content-left">



			<div class="col">
				<div class="card shadow-lg pb-5"
						 style="height:100%">
					<div class="card-body">
						<img :src="bi1"
								 style="height:60%"
								 class="carre1 mb-4"
								 alt="" />
						<h4 v-html="bt1">{{bt1}}</h4>
						<p v-html="bx1"
							 class="mb-2"> </p>
					</div>
					<a :href="link1"
						 style="text-align:right;padding-right : 25px;"
						 class="more hover link-yellow">{{$t('invest28')}}</a>
					<!--/.card-body -->
				</div>
				<!--/.card -->
			</div>
			<!--/column -->
			<div class="col">
				<div class="card shadow-lg pb-5"
						 style="height:100%">
					<div class="card-body">
						<img :src="bi2"
								 style="height:60%"
								 class="carre1 mb-4"
								 alt="" />
						<h4 v-html="bt2"> </h4>
						<p v-html="bx2"
							 class="mb-2"> </p>
					</div>
					<a :href="link2"
						 style="text-align:right;padding-right : 25px;"
						 class="more hover link-yellow">{{$t('invest28')}}</a>
					<!--/.card-body -->
				</div>
				<!--/.card -->
			</div>
			<!--/column -->
			<div class="col">
				<div class="card shadow-lg pb-5"
						 v-if='link3!=""'
						 style="height:100%">
					<div class="card-body">
						<img :src="bi3"
								 style="height:60%"
								 class="carre1 mb-4"
								 alt="" />
						<h4 v-html="bt3"> </h4>
						<p v-html="bx3"
							 class="mb-2"> </p>
					</div>
					<a :href="link3"
						 style="text-align:right;padding-right : 25px;"
						 class="more hover link-yellow">{{$t('invest28')}}</a>
					<!--/.card-body -->
				</div>
				<!--/.card -->
			</div>

		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
});


export default {
	name: 'block_image',
	components: {},
	props: {
		li1: String,
		li2: String,
		li3: String,
		li4: String,

		bt1: String,
		bt2: String,
		bt3: String,
		bt4: String,

		bx1: String,
		bx2: String,
		bx3: String,
		bx4: String,

		bi1: String,
		bi2: String,
		bi3: String,
		bi4: String,

		link1: String,
		link2: String,
		link3: String,
		link4: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
.carre1 {
	width: 100%;
	height: 40%;
	border: solid 0px silver;
	margin: 0px auto;
}
</style>
