<template>
  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row gx-lg-8 gx-xl-12 gy-6 mb-10">
        <div class="col-lg-6 order-lg-2">
          <ul class="progress-list">
            <li>
              <p>Web Design</p>
              <div class="progressbar line soft-violet" data-value="100"></div>
            </li>
            <li>
              <p>Mobile Design</p>
              <div class="progressbar line soft-blue" data-value="80"></div>
            </li>
            <li>
              <p>Development</p>
              <div class="progressbar line soft-leaf" data-value="85"></div>
            </li>
            <li>
              <p>SEO</p>
              <div class="progressbar line soft-pink" data-value="90"></div>
            </li>
          </ul>
          <!-- /.progress-list -->
        </div>
        <!--/column -->
        <div class="col-lg-6">
          <h2 class="display-4 mb-3">What I Do?</h2>
          <p class="lead fs-20 mb-5">Duis mollis est commodo luctus nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl.</p>
        </div>
        <!--/column -->
      </div>
      <!--/.row -->
      <div class="row gx-lg-8 gx-xl-12 gy-6 text-center">
        <div class="col-md-6 col-lg-3">
          <img src="@/assets/img/icons/browser.svg" class="svg-inject icon-svg icon-svg-md text-violet mb-3" alt="" />
          <h4>Web Design</h4>
          <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo cum sociis natoque magnis.</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3">
          <img src="@/assets/img/icons/smartphone.svg" class="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="" />
          <h4>Mobile Design</h4>
          <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo cum sociis natoque magnis.</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3">
          <img src="@/assets/img/icons/settings-3.svg" class="svg-inject icon-svg icon-svg-md text-leaf mb-3" alt="" />
          <h4>Development</h4>
          <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo cum sociis natoque magnis.</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3">
          <img src="@/assets/img/icons/search-1.svg" class="svg-inject icon-svg icon-svg-md text-pink mb-3" alt="" />
          <h4>SEO</h4>
          <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo cum sociis natoque magnis.</p>
        </div>
        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>


export default {
  name: 'account',
  components: {},
  props: {},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
